import React from "react";
import "./trading.css";
import { trading } from "../../data";
import { Agency, Client, Indserv, Indtop, Projects } from "../../components";

const Trading = () => {
  return (
    <div className="industry-page" style={{ "--clr": trading.color }}>
      <h2 className="main-title">{trading.name}</h2>
      <Indtop props={trading} />
      <Indserv props={trading} />
      <Client props={trading} />
      <Agency props={trading} />
      <Projects props={trading} />
    </div>
  );
};

export default Trading;
