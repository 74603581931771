import React from "react";
import "./about.css";
import { Industry } from "../../components";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import inds from "../../data";

const About = () => {
  return (
    <div className="about">
      <h2 className="main-title">من نحن</h2>
      <Fade>
        <div className="top back">
          <Zoom cascade>
            <div className="container">
              <img src={require("../../imgs/logo.webp")} alt="Logo" />
              <h3>
                شركات أبراج الطاقة هي مجموعة متنوعة من الشركات التي لها
                استثمارات في مجال واسع من الطاقة والطاقة التي تشمل:
              </h3>
            </div>
          </Zoom>
        </div>

        <div className="ind">
          <div className="container">
            {inds.map((e) => {
              return (
                <Fade key={e.id} bottom>
                  <Industry props={e} />
                </Fade>
              );
            })}
          </div>
        </div>

        <div className="leader back">
          <h2 className="main-title">القيادة</h2>
          <div className="container">
            <Zoom cascade>
              <img src={require("../../imgs/leader.png")} alt="Leader" />
              <div className="text">
                <h3>م. أحمد الجفن</h3>
                <p className="title">مدير عام</p>
                <p>
                  <span>م. أحمد الجفن</span>, مؤسسنا ، أسس شركات أبراج الطاقة في
                  2018 في المملكة العربية السعودية. رؤيته هي الإلهام الذي يوجه
                  الشركات إلى الأمام.
                </p>
              </div>
            </Zoom>
          </div>
        </div>

        <div className="bottom back">
          <h2 className="main-title">الجودة</h2>
          <Zoom cascade>
            <div className="container">
              <img src={require("../../imgs/stars.png")} alt="quality" />
              <p>
                تقدم شركات أبراج الطاقة منتجات وخدمات عالية الجودة للعملاء في
                جميع أنحاء المملكة العربية السعودية. تفتخر شركات أبراج الطاقة
                بتاريخها وتلتزم بمستقبلها. تعمل الشركات وفقًا لسياسات ضمان
                الجودة الصارمة الخاصة بالشركات. تعد أحدث التقنيات والحلول
                الحديثة وممارسات الإدارة المتقدمة في صميم مؤسستنا.
              </p>
            </div>
          </Zoom>
        </div>
      </Fade>
    </div>
  );
};

export default About;
