import React from "react";
import { Link } from "react-router-dom";
import "./landing.css";
import { Zoom } from "react-reveal";

const Landing = () => {
  return (
    <div className="landing">
      <div className="container">
        <Zoom cascade>
          <div className="text">
            <div className="writer">
              <h1>الطاقة: </h1>
              <ul>
                <li style={{ "--clr": "#fe8a11" }}>توليد</li>
                <li style={{ "--clr": "#854ad6" }}>توزيع</li>
                <li style={{ "--clr": "#f41e5b" }}>نقل</li>
              </ul>
            </div>
            {/* We Provide Greate Energy Servises */}
            <p>
              نحن مؤسسات سعودية وطنية معنية بالطاقة والبنية التحتية نحن نسعى
              جاهدين لتحسين خدماتنا وتطويرها باستمرار، وذلك من خلال تبني أحدث
              التقنيات العالمية في مجال الطاقة والبنية التحتية. كما نولي
              اهتمامًا كبيرًا لرضى عملائنا وتلبية احتياجاتهم. نواصل العمل بجدية
              وإخلاص لتقديم خدمات موثوقة وعالية الجودة.
            </p>
            <div className="links">
              <Link to="contact">تواصل معنا</Link>
              <Link to="industries">قطاعاتنا</Link>
            </div>
          </div>
        </Zoom>
        <img src={require("../../imgs/landing.webp")} alt="Landing" />
      </div>
    </div>
  );
};

export default Landing;
