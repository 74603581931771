import { Link } from "react-router-dom";
import "./nav.css";
const Nav = () => {
  return (
    <div className="nav">
      <div className="container">
        <Link to="/" className="logo">
          <img src={require("../../imgs/logo.png")} alt="Logo" />
        </Link>
        <ul className="links">
          <li
            onClick={() => {
              let nav = document.querySelector(".links");
              nav.classList.toggle("active");
            }}
          >
            <Link to="/">الرئيسية</Link>
          </li>
          <li
            onClick={() => {
              let nav = document.querySelector(".links");
              nav.classList.toggle("active");
            }}
          >
            <Link to="/industries">القطاعات</Link>
          </li>
          <li
            onClick={() => {
              let nav = document.querySelector(".links");
              nav.classList.toggle("active");
            }}
          >
            <Link to="/contact">تواصل معنا</Link>
          </li>
          <li
            onClick={() => {
              let nav = document.querySelector(".links");
              nav.classList.toggle("active");
            }}
          >
            <Link to="/about">من نحن</Link>
          </li>
          <li
            onClick={() => {
              let nav = document.querySelector(".links");
              nav.classList.toggle("active");
            }}
          >
            <a href="https://etco.com.sa" className="lang">
              EN
            </a>
          </li>
        </ul>

        <div
          onClick={(_) => {
            let nav = document.querySelector(".links");
            nav.classList.toggle("active");
          }}
          className="lines"
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
