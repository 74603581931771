import React from "react";
import "./client.css";
import Zoom from "react-reveal/Zoom";

const Client = ({ props }) => {
  return (
    <div className="client">
      <h2 className="main-title">العملاء</h2>
      <div className="container">
        {props.clinet.map((e) => {
          return (
            <Zoom key={e}>
              <img src={require(`../../imgs/${e}`)} alt="Client" />
            </Zoom>
          );
        })}
      </div>
    </div>
  );
};

export default Client;
