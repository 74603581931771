import React from "react";
import "./card.css";
import { Zoom } from "react-reveal";

const Card = ({ props }) => {
  return (
    <Zoom cascade>
      <div className="card" style={{ "--clr": props.color }}>
        <div className="img">
          <img src={require(`../../imgs/${props.img}`)} alt="Card" />
        </div>
        <div className="text">
          <h2>{props.name}</h2>
          <p>{props.text}</p>
        </div>
      </div>
    </Zoom>
  );
};

export default Card;
