import React from "react";
import "./indtop.css";
import Zoom from "react-reveal/Zoom";

const Indtop = ({ props }) => {
  return (
    <div className="top">
      <div className="container">
        <Zoom>
          <h3>{props.company}</h3>
          <p>{props.title}</p>
        </Zoom>
      </div>
    </div>
  );
};

export default Indtop;
