let inds = [
  {
    id: 1,
    img: "ind1",
    name: "التجارة",
    des: "في عام 2018 تم تأسيس شركة أبراج الطاقة للتجارة لتوفير المعدات الكهربائية. لقد شكلنا شراكات حصرية مع الشركات المصنعة والشركات.",
    color: "#fe8a11",
    url: "trading",
  },
  {
    id: 2,
    img: "ind2",
    name: "المقاولات",
    des: "منذ عام 2018 ، تم تخصيص شركة برج الطاقة للمقاولات لتقديم تركيبات كهربائية استثنائية ومشاريع تسليم مفتاح لقطاع الكهرباء.",
    color: "#854ad6",
    url: "contracting",
  },
  {
    id: 3,
    img: "ind3",
    name: "الهندسة",
    des: "تقدم شركة أبراج الطاقة للهندسة التصميم الهندسي وإدارة المشاريع والخدمات الاستشارية. تأسست عام 2021.",
    color: "#f41e5b",
    url: "engineering",
  },
];
export default inds;

export let msgs = [
  {
    id: "01",
    img: "1.png",
    color: "#f41e5b",
    name: "الرؤية",
    text: "نحن واثقون من مواردنا وفهمنا لاحتياجات العملاء. نعطي الأولوية للجودة والاكتفاء الذاتي ، بالاعتماد على خبرتنا لتوجيهنا نحو إمكاناتنا الحقيقية.",
  },
  {
    id: "02",
    img: "2.png",
    color: "#fe8a11",
    name: "قيمنا",
    text: "في صميم كل قرار نتخذه ، تكمن قيمنا - انعكاس لأخلاقياتنا وموقفنا المتجذر بعمق في جوهر اسمنا، يجب علينا دائمًا السعي لجعل قراراتنا تعكس أفضل قيم لدينا.",
  },
  {
    id: "03",
    img: "3.png",
    color: "#1ed3d7",
    name: "التقدم",
    text: "نحن مفكرون إلى الأمام. نحن نصر على أن نكون دائمًا في المقدمة بخطوة واحدة ، ونضخ أفضل الممارسات باستمرار من أجل تحسين الكفاءة. نحن نؤمن بالتكنولوجيا ونستثمر في المستقبل.",
  },
  {
    id: "04",
    img: "4.png",
    color: "#1384c8",
    name: "الإخلاص",
    text: "نحن مخلصون في التزامنا بتحقيق رؤيتنا. نحن صادقون في تفانينا في أن نكون مواطنين عالميين صالحين. صدقنا هو نتيجة لتاريخنا وثقافتنا الطويلة. نحن جادون في رغبتنا في النمو.",
  },
  {
    id: "05",
    img: "5.png",
    color: "#1b4c91",
    name: "الصدق",
    text: "نحن نسترشد بنزاهتنا. نتحلى بالشفافية في جميع تعاملاتنا ونكون صادقين في تقييماتنا. نحن نضمن أننا نوائم مصالحنا فقط مع تلك التي تحمل نفس التوافق مع الواقع.",
  },
  {
    id: "06",
    img: "6.png",
    color: "#854ad6",
    name: "المسؤولية",
    text: "نحن ملتزمون تجاه مساهمينا وشركائنا وموردينا والمجتمعات المحيطة بنا. نحن مسؤولون عن كل قرار نتخذه. نحن ملزمون بتحسين حياة شعبنا.",
  },
  {
    id: "07",
    img: "7.png",
    color: "#e64926",
    name: "المهارة",
    text: "نحن بارعون وقادرون وخبراتنا هي نتيجة خمسة عقود من المعرفة. توجهنا مجموعة المهارات الخاصة بنا نحو تقديم الجودة في كل من أعمالنا.",
  },
];

export let mission = [
  {
    id: 1,
    text: "توسيع نطاق منتجاتنا وخدماتنا من خلال الاستثمارات الاستراتيجية في الأعمال التجارية والفئات الجديدة. يمكّننا هذا النهج من تحقيق التكامل الأفقي والرأسي ، مما يؤدي في النهاية إلى تحسين جودة عروضنا والقيمة التي نقدمها لعملائنا.",
    color: "#fe8a11",
  },
  {
    id: 2,
    text: "توجيه الموارد بشكل استراتيجي للاستفادة القصوى من إمكانات الطاقة في أسواقنا الحالية، مع التركيز في الوقت ذاته على استكشاف أسواق الطاقة الجديدة وتحديد آفاق الأعمال المستقبلية.",
    color: "#854ad6",
  },
  {
    id: 3,
    text: "فهم احتياجات ومتطلبات عملائنا الكرام بشكل كامل وتقديم سلع وخدمات عالية الجودة تفوق توقعاتهم. علامتنا التجارية ، شركات أبراج الطاقة ، مرادفة للمعايير العالية والجودة الاستثنائية.",
    color: "#f41e5b",
  },
  {
    id: 4,
    text: "هدفنا هو تحفيز أعضاء فريقنا وتمكينهم من إطلاق العنان لإمكاناتهم الكاملة وتحقيق مآثر رائعة. في الوقت نفسه ، نحن نهدف إلى التوسع المستدام الذي يعود بالفائدة على جميع أصحاب المصلحة.",
    color: "#1b4c91",
  },
];

export let industries = [
  {
    id: 1,
    img: "section1.png",
    name: "التجارة",
    company: "شركة أبراج الطاقة للتجارة",
    text: "تأسست شركة أبراج الطاقة للتجارة في عام 2018 لتزويد عملائها بمعدات وأدوات كهربائية عالية الجودة. بصفتنا موردًا ذا سمعة طيبة في الصناعة ، فإننا نفخر بأنفسنا للحفاظ على اتفاقيات وكالة حصرية مع مجموعة من الشركات المصنعة والشركات المتميزة. يضمن التزامنا بالتميز والاحتراف أننا نقدم باستمرار منتجات وخدمات من الدرجة الأولى لعملائنا الكرام.",
    url: "trading",
    color: "#fe8a11",
  },
  {
    id: 2,
    img: "section2.png",
    name: "المقاولات",
    company: "شركة أبراج الطاقة للمقاولات",
    text: "تأسست في 2018 وهي مكرسة لتقديم خدمات استثنائية في مجال المشاريع الكهربائية. نحن متخصصون في التركيبات وكذلك المشاريع الجاهزة لقطاع الكهرباء. يتمتع فريقنا بمهارات عالية وملتزم بتقديم صنعة عالية الجودة تلبي احتياجات عملائنا. نحن نفخر بعملنا ونسعى جاهدين للحفاظ على مستوى التميز في جميع جوانب أعمالنا.",
    url: "contracting",
    color: "#854ad6",
  },
  {
    id: 3,
    img: "section3.png",
    name: "الهندسة",
    company: "شركة أبراج الطاقة للهندسة",
    text: "تأسست شركة أبراج الطاقة للهندسة في عام 2021 بهدف محدد هو تقديم خدمات هندسية استثنائية لعملائها. يتضمن ذلك تنفيذ التصاميم الهندسية وإدارة المشاريع والحلول الاستشارية. فريقنا مكرس لتقديم نتائج عالية الجودة لعملائنا بأقصى قدر من الاحتراف.",
    url: "engineering",
    color: "#f41e5b",
  },
];

export let trading = {
  name: "التجارة",
  company: "شركة أبراج الطاقة للتجارة",
  title:
    "تأسست في عام 2018 ، شركة أبراج الطاقة للتجارة المهتمة بتوفير المعدات والأدوات الكهربائية ولديها العديد من الوكالات الحصرية مع الشركات والمصنعين",
  color: "#fe8a11",
  services: [
    "1- توريد المعدات الكهربائية اللازمة للمشروع",
    "2- وكيل لشركات ومصانع متخصصة في المعدات الكهربائية",
  ],
  clinet: ["saudi.jpeg", "aramco.jpeg", "sabic.png", "marafiq.png"],
  projects: [
    {
      name: "Supply 13.8KV Switchgear for S/S 7809 & S/S 7808",
      client: "الشركة السعودية للكهرباء",
      img: "majlat.jpeg",
      contractor: "Majlat",
      price: "4,981,898  ريال",
      description:
        "Provide 13.8KV Switchgear for S/S 7809 & S/S 7808 in Qaseem Area in Saudi Arabia for الشركة السعودية للكهرباء with Total 44 Panels ",
    },
  ],
};

export let contracting = {
  name: "المقاولات",
  company: "شركة أبراج الطاقة للمقاولات",
  title:
    "تأسست شركة أبراج الطاقة للمقاولات عام 2018 ، وتهتم بتنفيذ المشاريع الكهربائية مثل التركيبات الكهربائية والمشاريع الجاهزة لقطاع الكهرباء",
  color: "#854ad6",
  services: [
    "1- التمديدات الكهربائية للمحطات الفرعية",
    "2- مشروع تسليم مفتاح في مجال الكهرباء",
  ],
  clinet: [
    "saudi.jpeg",
    "aramco.jpeg",
    "sabic.png",
    "marafiq.png",
    "abb.jpg",
    "semines.jpg",
    "altukhi.png",
  ],
  projects: [
    {
      name: "Electrical Erection for Substation # 8712",
      client: "الشركة السعودية للكهرباء",
      img: "abb.jpg",
      contractor: "ABB",
      price: "1,826,750  ريال",
      description:
        "Electrical Erection for Substation # 8712  in Al-Kharaj - KSA (132KV Substation)",
    },
    {
      name: "Electrical Erection for Substation # 8709 ",
      client: "الشركة السعودية للكهرباء",
      img: "abb.jpg",
      contractor: "ABB",
      price: "1,188,000  ريال",
      description:
        "Electrical Erection for Substation # 8709 in Wadi Al-Dwaser , KSA  (132KV Substation)",
    },
    {
      name: "Electrical Erection for Substation # 8705",
      client: "الشركة السعودية للكهرباء",
      img: "abb.jpg",
      contractor: "ABB",
      price: "705,000  ريال",
      description:
        "Electrical Erection for Substation # 8705 in Al-Kharj , KSA  (132KV Substation)",
    },
    {
      name: "Electrical Erection for AlKharaj SVC",
      client: "الشركة السعودية للكهرباء",
      img: "semines.jpg",
      contractor: "Semines",
      price: "1,950,000  ريال",
      description: "Electrical Erection for AlKharj SVC in Al-Kharj , KSA",
    },
    {
      name: "Electrical Erection for AlQweah SVC",
      client: "الشركة السعودية للكهرباء",
      img: "semines.jpg",
      contractor: "Semines",
      price: "500,000  ريال",
      description: "Electrical Erection for AlQweah SVC in AlQweah , KSA",
    },
    {
      name: "Electrical Erection for Substation # 9063",
      client: "الشركة السعودية للكهرباء",
      img: "altukhi.png",
      contractor: "Altokhi",
      price: "500,000 ريال",
      description:
        "Electrical Erection for Substation # 9063 in Al-Jilah , KSA  (380KV Substation)",
    },
    {
      name: "Electrical Erection for Substation # 9775",
      client: "الشركة السعودية للكهرباء",
      img: "altukhi.png",
      contractor: "Altokhi",
      price: "600,000 ريال",
      description:
        "Electrical Erection for Substation # 9063 in Khamis Mushit , KSA  (380KV Substation)",
    },
  ],
};

export let engineering = {
  name: "الهندسة",
  company: "شركة أبراج الطاقة للهندسة",
  title:
    "تأسست عام 2021 ، شركة أبراج الطاقة للهندسة المعنية بتنفيذ التصاميم الهندسية وإدارة المشاريع والاستشارات الهندسية",
  color: "#f41e5b",
  services: [
    "1- التصميم الهندسي",
    "2- إدارة المشروع",
    "3- الاستشارات الهندسية",
  ],
  clinet: [
    "zj.png",
    "advance.jpg",
    "nour.jpg",
    "carlo.jpg",
    "altukhi.png",
    "ajlan.png",
  ],
  projects: [
    {
      name: "Issue FAC(Final Acceptance Certificate )",
      client: "الشركة السعودية للكهرباء",
      img: "nour.jpg",
      contractor: "Nour",
      price: "50,000  ريال",
      description:
        "Issue FAC(Final Acceptance Certificate )  for Tows Projects",
    },
    {
      name: "Close Project and Get Final Payment (total amount : 8M ريال)",
      client: "الشركة السعودية للكهرباء",
      img: "nour.jpg",
      contractor: "Nour",
      price: "150,000  ريال",
      description:
        "Close Old projects and get the final payment for these project with total amount ( 8 M ريال)",
    },
    {
      name: "Prequalification for 132 KV Substation (EPC) with SEC",
      client: "الشركة السعودية للكهرباء",
      img: "advance.jpg",
      contractor: "Advance Energy Company",
      price: "50,000  ريال",
      description: "Prequalification for 132 KV Substation (EPC) with SEC",
    },
    {
      name: "Prequalification for 132KV Substation (EPC) with SEC",
      client: "الشركة السعودية للكهرباء",
      img: "carlo.jpg",
      contractor: "Carlo Gavazzi Arabia",
      price: "30,000  ريال",
      description: "Prequalification for 132 KV Substation (EPC) with SEC",
    },
    {
      name: "Issue FAC (Final Acceptance Certificate )",
      client: "الشركة السعودية للكهرباء",
      img: "altukhi.png",
      contractor: "Altokhi",
      price: "50,000  ريال",
      description: "Issue FAC(Final Acceptance Certificate )  for one Project",
    },
    {
      name: "Prequalification Chint for 132 KV Substation (Manufacturer ) with SEC",
      client: "الشركة السعودية للكهرباء",
      img: "ajlan.png",
      contractor: "Ajlan & Bro",
      price: "600,000  ريال",
      description:
        "Prequalification for 132 KV Substation (Manufacturer) with SEC",
    },
  ],
};
