import React from "react";
import "./contracting.css";
import { contracting } from "../../data";
import { Agency, Client, Indserv, Indtop, Projects } from "../../components";
import Fade from "react-reveal/Fade";

const Contracting = () => {
  return (
    <div className="industry-page" style={{ "--clr": contracting.color }}>
      <h2 className="main-title">{contracting.name}</h2>
      <Fade bottom>
        <Indtop props={contracting} />
        <Indserv props={contracting} />
        <Client props={contracting} />
        <Projects props={contracting} />
      </Fade>
    </div>
  );
};

export default Contracting;
