import React from "react";
import "./industries.css";
import { IndSection } from "../../components";
import { industries } from "../../data";
import Fade from "react-reveal/Fade";

const Industries = () => {
  return (
    <div className="industries">
      <div className="holder">
        {industries.map((e) => {
          return (
            <Fade key={e.id} bottom>
              <IndSection props={e} />
            </Fade>
          );
        })}
      </div>
    </div>
  );
};

export default Industries;
