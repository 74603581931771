import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  About,
  Contact,
  Home,
  Industries,
  Contracting,
  Engineering,
  Trading,
} from "./Pages";
import { Nav, Footer, Scroll } from "./components";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Scroll />
        <Nav />
        <Routes basename="/">
          <Route element={<Home />} path="/" />
          <Route element={<Contact />} path="/contact" />
          <Route element={<Industries />} path="/industries" />
          <Route element={<About />} path="/About" />
          <Route element={<Trading />} path="/trading" />
          <Route element={<Contracting />} path="/contracting" />
          <Route element={<Engineering />} path="/engineering" />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
