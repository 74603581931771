import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { Zoom } from "react-reveal";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <Zoom cascade>
          <img src={require("../../imgs/logo.webp")} alt="LOGO" />
          <div className="text">
            <div className="top">
              <h3>شركات أبراج الطاقة</h3>
              {/* <p>
                نحن مؤسسات سعودية وطنية معنية بالطاقة والبنية التحتية نحن نسعى
                جاهدين لتحسين خدماتنا وتطويرها باستمرار، وذلك من خلال تبني أحدث
                التقنيات العالمية في مجال الطاقة والبنية التحتية. كما نولي
                اهتمامًا كبيرًا لرضى عملائنا وتلبية احتياجاتهم. نواصل العمل
                بجدية وإخلاص لتقديم خدمات موثوقة وعالية الجودة.
              </p> */}
            </div>
            <ul className="links">
              <li>
                <Link to="/">الرئيسية</Link>
              </li>
              <li>
                <Link to="/industries">القطاعات</Link>
              </li>
              <li>
                <Link to="/contact">تواصل معنا</Link>
              </li>
              <li>
                <Link to="/about">من نحن</Link>
              </li>
            </ul>
            <div className="bottom">
              <h4>جميع الحقوق محفوظة</h4>
              <div className="social">
                <a
                  href="https://wa.me/966500338866"
                  aria-label="whatsapp"
                  target="_blank"
                  rel="noreferrer"
                  className="whatsapp"
                >
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
                <a
                  href="https://twitter.com/etco_sa"
                  aria-label="twitter"
                  target="_blank"
                  rel="noreferrer"
                  className="twitter"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/energy-twoer-company/"
                  aria-label="linkedin"
                  target="_blank"
                  rel="noreferrer"
                  className="linkedin"
                >
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

export default Footer;
