import React from "react";
import "./indserv.css";
import Zoom from "react-reveal/Zoom";

const Indserv = ({ props }) => {
  return (
    <div className="services">
      <h2 className="main-title">الخدمات</h2>
      <div className="container">
        {props.services.map((e) => {
          return (
            <Zoom key={e}>
              <div className="box">
                <div className="d1"></div>
                <div className="d2"></div>
                <p>{e}</p>
              </div>
            </Zoom>
          );
        })}
      </div>
    </div>
  );
};

export default Indserv;
