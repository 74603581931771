import React from "react";
import "./engineering.css";
import { engineering } from "../../data";
import { Agency, Client, Indserv, Indtop, Projects } from "../../components";

const Engineering = () => {
  return (
    <div className="industry-page" style={{ "--clr": engineering.color }}>
      <h2 className="main-title">{engineering.name}</h2>
      <Indtop props={engineering} />
      <Indserv props={engineering} />
      <Client props={engineering} />
      <Projects props={engineering} />
    </div>
  );
};

export default Engineering;
